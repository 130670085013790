<template>
  <v-dialog
    :value="dialog"
    :fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
    max-width="600"
    overlay-opacity="0.7"
    scrollable
    :persistent="persistent"
    transition="dialog-bottom-transition"
    @input="closeModal"
  >
    <v-card
      tile
      color="element"
    >
      <v-card-title
        class="pa-0"
        v-if="title"
      >
        <v-toolbar
          color="primary"
        >
          <v-toolbar-title
            class="black--text"
          >{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            id="cancel-modal"
            class="black--text"
            icon
            @click="closeModal"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <slot
          name="content"
        >
          <h3>Modal Content</h3>
          <p>Use as <code><div slot="body">Foobar</div></code></p>
        </slot>
      </v-card-text>
      <div v-if="!noPadding" style="flex: 1 1 auto; height: 20px;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    saveButton: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    saveDataInModal() {
      this.$bus.$emit('save-data');
      // check if the data has been saved
      // successfully
      this.closeModal();
    },
  },
};
</script>
