<template>
  <div>
    <p>{{ $t('onboarding.classification.conditions.intro')}}</p>
    <br>
    <div
      v-for="condition in Object.keys(conditions)"
      :key="condition"
    >
      <template v-if="condition !== 'profession'">
        <p class="mt-3">
          {{ $t(`onboarding.classification.conditions.conditions.${condition}.question`) }}
        </p>
        <v-radio-group
          v-model="data.classification.conditions[condition]"
        >
          <v-radio
            v-for="(answer, index) in answers"
            :key="index"
            :label="$t(`onboarding.classification.conditions.conditions.${condition}.answer.${answer.text}`)"
            :value="answer.value"
            class="mt-2"
            :id="condition+answer.text"
          ></v-radio>
        </v-radio-group>
      </template>
      <template v-else>
        <p
          class="mt-3"
          v-if="data.classification.conditions.workExperienceFinancialSector"
          v-html="$t('onboarding.classification.conditions.conditions.profession.title')"
        ></p>
        <v-form
          v-if="data.classification.conditions.workExperienceFinancialSector"
          v-model="isFormValid"
          @submit.prevent="submit"
        >
          <v-text-field
            class="mt-5"
            id="profession"
            type="text"
            :rules="rules.required"
            v-model="data.classification.conditions[condition]"
            required
            :label="`${$t('onboarding.classification.conditions.conditions.profession.placeholder')} *`"
            outlined
            validate-on-blur
            ref="profession"
            @input="checkValidation('profession')"
          ></v-text-field>
        </v-form>
      </template>
    </div>
    <a v-if="!settings" @click="openSkipModal">
      <u>{{ $t('onboarding.classification.investorClassification.skip.title') }}</u>
    </a>
    <Modal
      :dialog="isSkipModalOpen"
    >
      <template v-slot:content>
        <div class="mt-10 d-flex flex-column">
          <p class="defaultText--text">
            {{ $t('onboarding.classification.investorClassification.skip.modal.question') }}
          </p>
          <div class="d-flex justify-center">
            <v-btn
              color="primary"
              class="primary--text mt-5 mr-2"
              outlined
              @click="closeSkipModal"
            >
              {{ $t('onboarding.classification.investorClassification.skip.modal.answer.no') }}
            </v-btn>
            <v-btn
              color="primary"
              class="black--text mt-5 ml-2"
              @click="next(true)"
            >
              {{ $t('onboarding.classification.investorClassification.skip.modal.answer.yes') }}
            </v-btn>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :dialog="isModalOpen"
      @close="closeModal"
    >
      <template v-slot:content>
        <div class="mt-5 d-flex flex-column">
          <p>
            {{ $t(`onboarding.classification.conditions.modalDescription.${investorType}`) }}
          </p>
          <v-btn
            color="primary"
            class="align-self-center black--text"
            @click="next(false)"
            id="modal-next"
          >
            {{ $t('onboarding.navButtons.next') }}
          </v-btn>
        </div>
      </template>
    </Modal>
    <Modal
      :dialog="isEditModalOpen"
      @close="closeEditModal"
      noPadding
    >
      <template v-slot:content>
        <div class="mt-5 d-flex flex-column defaultText--text">
          <p class="font-weight-medium">
            {{ $t(`settings.investorClassification.conditions.prok.${prok}.title`) }}
          </p>
          <p>
            {{ $t(`settings.investorClassification.conditions.prok.${prok}.desc`) }}
          </p>
          <v-btn
            color="primary"
            class="align-self-center black--text"
            @click="saveEdit"
            id="modal-next"
          >
            {{ $t(`settings.investorClassification.conditions.prok.${prok}.btn`) }}
          </v-btn>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import { required } from '@/utils/validators';
import Modal from '@/components/Shared/Modal.vue';

export default {
  name: 'Conditions',
  components: {
    Modal,
  },
  props: {
    settings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        required,
      },
      answers: [
        {
          text: 'no',
          value: false,
        },
        {
          text: 'yes',
          value: true,
        },
      ],
      isModalOpen: false,
      isSkipModalOpen: false,
      isEditModalOpen: false,
    };
  },
  computed: {
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
    ...mapGetters('settings', [
      'classification',
    ]),
    conditions() {
      return this.data.classification.conditions;
    },
    investorType() {
      return this.data.classification.conditions.workExperienceFinancialSector
        && this.data.classification.conditions.wealthOver500K ? 'pro' : 'private';
    },
    prok() {
      const { wealthOver500K, workExperienceFinancialSector } = this.data.classification.conditions;
      return wealthOver500K && workExperienceFinancialSector;
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (!newVal.classification.conditions.workExperienceFinancialSector) {
          this.setFormValidity(true);
        }
      },
      deep: true,
    },
  },
  created() {
    this.setFormValidity(true);
    if (this.settings) {
      this.data.classification = this.classification;
    }
  },
  methods: {
    ...mapActions('onboarding', [
      'setFormValidity',
    ]),
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'setMainStage',
      'updateOnboarding',
    ]),
    ...mapActions('settings', [
      'nextInvestorClassificationStep',
      'setClassificationConditions',
    ]),
    checkValidation(refName) {
      if (!this.isFormValid) {
        this.$refs[refName][0].validate();
      }
    },
    closeModal() {
      this.isModalOpen = false;
    },
    submit() {
      this.isModalOpen = true;
    },
    openSkipModal() {
      this.isSkipModalOpen = true;
    },
    closeSkipModal() {
      this.isSkipModalOpen = false;
    },
    openEditModal() {
      this.isEditModalOpen = true;
    },
    closeEditModal() {
      this.isEditModalOpen = false;
    },
    async next(abort) {
      const { wealthOver500K, workExperienceFinancialSector, profession } = this.data.classification.conditions;
      const conditions = {
        wealthOver500K,
        workExperienceFinancialSector,
      };
      if (workExperienceFinancialSector) {
        conditions.profession = profession;
      }
      conditions.abortProcess = abort;
      const input = {
        investor: {
          classification: {
            conditions,
          },
        },
        mainStage: 'classification',
        subStage: 'conditions',
      };
      try {
        const nextStage = await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (nextStage.nextMainStage === 'videoident') {
          this.setSubStage(nextStage.nextSubStage);
          this.setMainStage(nextStage.nextMainStage);
          this.$router.push({
            name: 'Onboarding.Overview',
          });
        } else {
          this.setSubStage('investorclassification');
        }
        this.isModalOpen = false;
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    edit() {
      this.openEditModal();
    },
    saveEdit() {
      if (this.prok) {
        const { wealthOver500K, workExperienceFinancialSector, profession } = this.data.classification.conditions;
        this.setClassificationConditions({
          wealthOver500K,
          workExperienceFinancialSector,
          profession,
        });
        this.nextInvestorClassificationStep();
      } else {
        this.$router.push('/settings/knowledge-experience');
      }
    },
  },
};
</script>

<style scoped>
.font-weight-semi-bold {
  font-weight: 600;
}
</style>
